import React from 'react'
import Layout from '../../components/layout'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const Faq7 = () => {
  return (
    <>
      <Layout>
        <Container>
          <Row>
            <div className="back-button mb-4 mt-3">
              <Link to="/faq/">&larr; Back</Link>
            </div>
            <Col sm="12">
              <div className="mb-5">
                <StaticImage
                  src="../../images/woman-deep-pink-background.jpg"
                  alt="Woman"
                  placeholder="none"
                  layout="fullWidth"
                />
                <h2 className="mt-3">
                  Is the Lumineers<sup>&reg;</sup> procedure painful?
                </h2>
                <p>
                  Lumineers<sup>&reg;</sup> are placed by dentists trained in
                  painless minimally invasive techniques that typically require
                  no shots or aggressive drilling.
                </p>
              </div>
              <div className="mb-5 text-center">
                <Link to="../../procedure/" className="intro-button mb-5">
                  Learn More
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default Faq7
